<template>
  <div class="page">
    <div class="banner">
      <img :src="info.image" alt="" />
    </div>
    <div class="title">{{ info.title }}</div>
    <v-h5Mtml :content="info.content"></v-h5Mtml>
  </div>
</template>

<script>
import { getTipsDetailsUrl } from "./api.js";
export default {
  name: "decorationTipsApplyDetail",
  data() {
    return {
      info: {},
    };
  },
  async created() {
    await this.getInfo(this.$route.query.id);
  },
  methods: {
    async getInfo(id) {
      let params = {
        id: id,
      };
      let res = await this.$axios.get(`${getTipsDetailsUrl}`, {
        params: params,
      });
      if (res.code == 200) {
        this.info = res.data;
      }
    },
  },
};
</script>

<style scoped lang="less">
.page {
  width: 100%;
  min-height: 100vh;
  padding-top: 0;
  padding-bottom: 60px;
  box-sizing: border-box;
  background: #fff;
  .banner {
    width: 100%;
    height: 456px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .content {
    font-size: 30px;
    color: #333333;
    padding: 0 45px;
    box-sizing: border-box;
    word-break: break-all;
  }
  .title {
    font-size: 36px;
    font-weight: 600;
    color: #333333;
    padding: 42px 45px 68px;
    box-sizing: border-box;
  }
}
</style>
