import { render, staticRenderFns } from "./decorationTipsDetail.vue?vue&type=template&id=9e1ed712&scoped=true"
import script from "./decorationTipsDetail.vue?vue&type=script&lang=js"
export * from "./decorationTipsDetail.vue?vue&type=script&lang=js"
import style0 from "./decorationTipsDetail.vue?vue&type=style&index=0&id=9e1ed712&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e1ed712",
  null
  
)

export default component.exports